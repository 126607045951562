@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  color: white;
  background-color: var(--color-1) !important;
}

:root {
  --color-1: #1c0522;
  --color-2: #2b1055;
}