.loader-main-div {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.4);
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 60px;
    aspect-ratio: 1;
    color: var(--color-2);
    position: relative;
}

.loader::before,
.loader::after {
    content: "";
    position: absolute;
    inset: 0;
    background:
        linear-gradient(currentColor 0 0) 0 calc(var(--s, 0)*-100%)/100% calc(100%/3),
        repeating-linear-gradient(90deg, currentColor 0 25%, #0000 0 50%) calc(var(--s, 0)*100%) 50%/calc(4*100%/3) calc(100%/3);
    background-repeat: no-repeat;
    animation: l26 2s infinite;
}

.loader::after {
    --s: -1;
}

@keyframes l26 {

    0%,
    10% {
        transform: translateY(calc(var(--s, 1)*0));
        background-position: 0 calc(var(--s, 0)*-100%), calc(var(--s, 0)*100%) 50%
    }

    33% {
        transform: translateY(calc(var(--s, 1)*-20%));
        background-position: 0 calc(var(--s, 0)*-100%), calc(var(--s, 0)*100%) 50%
    }

    66% {
        transform: translateY(calc(var(--s, 1)*-20%));
        background-position: 0 calc(var(--s, 0)*-100%), calc(var(--s, 0)*100% + 100%) 50%
    }

    90%,
    100% {
        transform: translateY(calc(var(--s, 1)*0));
        background-position: 0 calc(var(--s, 0)*-100%), calc(var(--s, 0)*100% + 100%) 50%
    }
}