.about-hero {
    background-image: url('../../Assets/About/about-hero-img.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.about-us-container {
    color: white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about-us-container>h1 {
    font-size: 4rem;
}

.about-us-container>p {
    text-align: center;
    font-size: 18px;
}