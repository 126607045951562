.hero-sec-main-div {
    width: 100%;
    height: 100vh;
    position: relative;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-2) !important;
    overflow: hidden;
}

.hero-sec-main-div::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(to top, var(--color-1), transparent);
    z-index: 1000;
}

.hero-sec-main-div>img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.moon {
    mix-blend-mode: screen;
}

.mountain-front {
    z-index: 10;
}

.hero-sec-main-div>h2 {
    position: absolute;
    color: white;
    white-space: nowrap;
    font-size: 6rem;
    z-index: 9;
    right: -350px;
    /* transform: translateY(100px); */
}

.hero-sec-main-div>button {
    background-color: white;
    padding: 15px 40px;
    color: var(--color-2);
    position: absolute;
    z-index: 11;
    border: none;
    border-radius: 18px;
    font-size: 28px;
    transform: translateY(200px);
}

.btn-link {
    text-decoration: none;
    color: var(--color-2);
}

@media only screen and (max-width:2000px) {
    .hero-sec-main-div>h2 {
        font-size: 6.5rem;
        right: -450px;
    }
}

@media only screen and (max-width:1200px) {
    .hero-sec-main-div>h2 {
        font-size: 6rem;
        right: -500px;
    }
}

@media only screen and (max-width:996px) {
    .hero-sec-main-div>h2 {
        font-size: 5.5rem;
        right: -550px;
    }
}

@media only screen and (max-width:576px) {
    .hero-sec-main-div {
        height: 60vh;
    }

    .hero-sec-main-div>h2 {
        font-size: 4rem;
        right: -550px;
    }

    .hero-sec-main-div>button {
        padding: 10px 20px;
        font-size: 20px;
        margin-left: 20px;
    }
}