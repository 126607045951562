.hidden-input {
    display: none;
}

.icon-check {
    color: white;
    font-size: 70px;
    border-radius: 50%;
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000;
    background-color: var(--color-1);
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons-div {
    position: fixed;
    bottom: 20px;
    right: 20px;
    transform: translate(-50%, -50%);
    font-size: 2em;
    border-radius: 50%;
    z-index: 99;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 75, 0.885, 0.32, 1.275);
    cursor: pointer;
    opacity: 0;
    padding: 18px;
    color: white;
}

#chk:checked~.icons-div-1 {
    background-color: #075E54;
    transform: translateY(-3em) translateX(-0.1em);
    opacity: 1;
    transition-delay: .2s;
}

#chk:checked~.icons-div-2 {
    background-color: #000;
    transform: translateY(-6em) translateX(-0.1em);
    opacity: 1;
    transition-delay: .2s;
}

@media only screen and (max-width:576px) {
    .icon-check {
        font-size: 50px;
        bottom: 10px;
        right: 10px;
    }
}