.footer-main-div {
    margin-top: 150px;
    position: relative;
    height: 400px;
    background-color: var(--color-2);
    /* background-image: url('../../Assets/Hero/stars.png'); */
}

/* .custom-shape-divider-bottom-1724258078 {
    position: absolute;
    top: -140px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1724258078 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 140px;
}

.custom-shape-divider-bottom-1724258078 .shape-fill {
    fill: var(--color-2);
} */

.footer-section {
    width: 100%;
    height: 100%;
    position: relative;
}

/* animation video code start from here*/

.background-video-div {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.background-video-div>video {
    width: 100%;
    height: auto;
}

/* animation video code end here*/

.footer-container {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.footer-container::after {
    background-image: url('../../Assets/footer-logo-1.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    right: 0;
    top: 20%;
    z-index: -1;
    width: 300px;
    height: 220px;
    transform: rotateZ(20deg);
}

.logo-div-footer {
    width: 40%;
    height: 100%;
    padding-top: 50px;
}

.logo-div-footer>img {
    width: 60%;
}

.logo-div-footer>p {
    color: white;
    margin-top: 30px;
}

.quick-links-div {
    width: 28%;
    height: 100%;
}

.quick-links-div>h2 {
    margin-top: 50px;
    color: white;
    font-size: 3rem;
}

.quick-links-div>ul {
    color: white;
}

.footer-links {
    text-decoration: none;
    margin: 0;
    color: white;
    font-size: 18px;
    line-height: 30px;
}

.quick-links-div-2>ul {
    list-style: none;
    padding: 0;
}

.quick-links-div-2>div {
    display: flex;
}

.footer-icons {
    width: 50px;
    height: 50px;
    background-color: white;
    color: var(--color-2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    transition: all .5s;
    margin-right: 10px;
}

.footer-icons:hover {
    background-color: var(--color-1);
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.bottom-footer {
    width: 100%;
    padding: 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    color: rgba(255, 255, 255, 0.7);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 968px) {
    .background-video-div>video {
        width: auto;
        height: 100%;
    }

    .footer-container {
        padding-bottom: 100px !important;
    }
}

@media only screen and (max-width:768px) {

    .footer-main-div {
        height: auto;
    }

    .logo-div-footer {
        width: 100%;
        height: auto;
    }


    .quick-links-div {
        width: 50%;
        height: auto;
    }
}

@media only screen and (max-width:576px) {

    .logo-div-footer {
        width: 100%;
        height: auto;
    }


    .quick-links-div {
        width: 100%;
        height: auto;
    }

}