.service-main {
    background-color: var(--color-1);
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.service-container {
    color: white;
    padding: 50px 0px;
}

.service-container>h1 {
    font-size: 4.5rem;
    text-align: center;
}

.services-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.specific-service-div {
    background-color: white;
    width: 33%;
    height: 500px;
    margin-top: 50px;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    transition: all .5s ease-in-out;
    padding: 40px 20px;
}


.service-icon-div {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-1);
    font-size: 4.7rem;
    border-radius: 50%;
    background-color: rgb(240, 144, 54);
}

.service-text-div {
    width: 100%;
    height: 300px;
}

.service-text-div>h2 {
    color: var(--color-1);
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.service-text-div>p {
    text-align: justify;
    color: var(--color-1);
}


.specific-service-div:hover {
    transform: scale(1.1);
    background-color: var(--color-2);
}

.specific-service-div:hover .service-text-div>p,
.specific-service-div:hover .service-text-div>h2 {
    color: white !important;
}

@media only screen and (max-width:992px) {
    .specific-service-div {
        width: 49%;
    }
}

@media only screen and (max-width:992px) {
    .specific-service-div {
        width: 49%;
    }

    .service-text-div>h2 {
        font-size: 2rem;
    }
}

@media only screen and (max-width:576px) {
    .service-container>h1 {
        font-size: 3.5rem;
    }

    .services-div {
        justify-content: center;
    }

    .specific-service-div {
        width: 80%;
        height: auto;
    }

    .service-text-div>h2 {
        font-size: 2rem;
    }

    .service-text-div {
        height: auto;
    }
}