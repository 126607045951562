.service-hero,
.service-img-main {
    width: 100%;
    height: 100vh;
}

.service-hero-container,
.service-img-container {
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background-image: url('../../Assets/Services/service-hero.png');
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-position: right;
}

.graphic-hero-container {
    background-image: url('../../Assets/Graphic/service-hero.png');
}

.hero-text-div {
    width: 68%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero-text-div>h1 {
    font-size: 4rem;
    font-weight: 600;
    color: white;
}

.hero-text-div>p {
    color: grey;
    font-size: 18px;
}

.contact-btn {
    width: 370px;
    height: 60px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 20px;
    color: white;
    font-weight: 500;
    font-size: 18px;
}

.contact-btn:hover {
    background-color: var(--color-2);
    border: none;
}

.main-img-div {
    width: 100%;
    padding: 100px 0px;
    /* background-color: aquamarine; */
    overflow: hidden;
}

.main-img-div>h1,
.main-img-div>h2 {
    color: white;
    text-align: center;
    font-weight: 600;
}

.main-img-div>h1 {
    font-size: 5rem;
    background-color: white;
    color: var(--color-1);
    padding: 20px;
    position: relative;
}


.main-img-div>h1>p {
    margin: 0;
}

.main-img-div>h2 {
    font-size: 3rem;
    margin-top: 50px;
}

.img-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 100px
}

.comic-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 100px
}

.img-container .img-div {
    width: 350px;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 20px;
    position: relative;
}

.img-container .twitch-img-div {
    width: 400px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 20px;
    position: relative;
}

.img-container .dnd-img-div {
    width: 400px;
    height: 500px;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 20px;
    position: relative;
}

.img-container .logo-img-div {
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 20px;
    position: relative;
}

.img-container .reference-img-div {
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 20px;
    position: relative;
}

/* Carousel */

.main-carousel {
    width: 100%;
    height: 100%;
    position: relative;
}

.carousel-div {
    width: 350px;
    height: 400px;
    position: relative;
}

.carousel-div>img {
    width: 100%;
    height: 100%;
}

.carousel-arrows {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    font-size: 40px;
    position: absolute;
    top: 45%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.left-arrow {
    left: 10px;
}

.right-arrow {
    right: 10px;
}

.demoPage {
    background-color: white !important;
}

.demoPage>img {
    width: 100%;
    height: 100%;
}

.demo-page-1 {
    position: relative;
}

.demo-page-1::after {
    content: "Click Me";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.demo-page-1:hover::after {
    opacity: 1;
}


@media only screen and (max-width:768px) {

    .service-hero-container,
    .service-img-container {
        background-position: center;
    }

    .hero-text-div {
        width: 100%;
        height: 100%;
    }

    .main-img-div>h1 {
        font-size: 4.5rem;
    }

    .hero-text-div>h1 {
        font-size: 3rem;
    }


    .hero-text-div>p {
        color: grey;
        font-size: 16px;
    }
}

@media only screen and (max-width:576px) {

    .service-hero,
    .service-img-main {
        height: auto;
        padding: 100px 0px 50px 0px;
    }

    .contact-btn {
        width: 100%;
    }

    .hero-text-div>h1 {
        font-size: 2.7rem;
    }

    .hero-text-div>p {
        font-size: 14px;
    }

    .main-img-div {
        padding: 50px 0px;
    }

    .main-img-div>h1 {
        font-size: 3rem;
    }

    .main-img-div>h2 {
        font-size: 2.6rem;
    }

    .demo-page-1::after {
        opacity: 1;
    }
}

@media only screen and (max-width:430px) {
    .img-container .img-div {
        width: 300px;
        height: 350px;
    }

    .img-container .twitch-img-div {
        width: 300px;
    }

    .img-container .reference-img-div {
        height: 270px;
    }

    .img-container .dnd-img-div {
        width: 300px;
        height: 350px;
    }
}