.service-hero {
    width: 100%;
    height: 100vh;
}

.service-hero-container {
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background-image: url('../../Assets/Services/service-hero.png');
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-position: right;
}

.hero-text-div {
    width: 68%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero-text-div>h1 {
    font-size: 4rem;
    font-weight: 600;
    color: white;
}

.hero-text-div>p {
    color: grey;
    font-size: 18px;
}

.contact-btn {
    width: 370px;
    height: 60px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 20px;
    color: white;
    font-weight: 500;
    font-size: 18px;
}

.contact-btn:hover {
    background-color: var(--color-2);
    border: none;
}