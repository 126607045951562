.counter-main {
    background-color: var(--color-1);
    width: 100%;
    height: 120vh;
    overflow: hidden;
}

.counter-container {
    color: white;
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.counter-div-main {
    width: 100%;
    height: 390px;
    /* border: 1px solid white; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.counter-hd-1,
.counter-hd-2 {
    font-size: 8rem;
    text-align: center;
    font-weight: 900;
    position: absolute;
    z-index: 0;
}


.counter-hd-1 {
    top: -110px;
}

.counter-hd-2 {
    bottom: -110px;

}

.counter-cards-main {
    width: 100%;
    height: 100%;
    /* background-color: red; */
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.counter-card {
    width: 22%;
    height: 100%;
    background-color: white;
    transition: all .5s;
    color: var(--color-1);
    cursor: pointer;
}

.counter-card:hover {
    transform: skewY(-10deg);
    background-color: var(--color-2);
    color: white;
}

.counter-card:hover .text-div {
    background-color: var(--color-2);
    border-bottom: 1px solid rgba(225, 225, 225, 0.5);
}

.counter-card:hover .number-div {
    background-color: var(--color-2);
}

.text-div {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    transition: all .5s;
}

.text-div>p {
    padding-top: 40px;
    padding-left: 20px;
}

.text-div>h3 {
    padding-left: 20px;
    padding-bottom: 20px;
    width: 100%;
}

.number-div {
    width: 100%;
    height: 30%;
    transition: all .5s;
    padding: 40px;
}

.counter-icon-div {
    width: 100%;
    font-size: 5rem;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width:1200px) {

    .counter-hd-1,
    .counter-hd-2 {
        font-size: 6.5rem;
    }

    .counter-hd-1 {
        top: -88px;
    }

    .counter-hd-2 {
        bottom: -88px;
    }

    .counter-card {
        width: 22.5%;
    }
}

@media only screen and (max-width:992px) {
    .text-div>p {
        padding-top: 20px;
        padding-left: 10px;
    }

    .text-div>h3 {
        padding-left: 10px;
        padding-bottom: 10px;
        font-size: 1.5rem;
    }

    .counter-icon-div {
        font-size: 4.8rem;
    }

    .counter-hd-1,
    .counter-hd-2 {
        font-size: 5rem;
    }

    .counter-hd-1 {
        top: -70px;
    }

    .counter-hd-2 {
        bottom: -75px;
    }

}

@media only screen and (max-width:768px) {
    .counter-div-main {
        height: 790px;
    }

    .counter-hd-1,
    .counter-hd-2 {
        font-size: 3.8rem;
        font-weight: 800;
    }

    .counter-hd-1 {
        top: -45px;
    }

    .counter-hd-2 {
        bottom: -55px;
    }

    .counter-card {
        width: 48%;
        height: 48%;
    }
}

@media only screen and (max-width:576px) {
    .counter-div-main {
        height: 700px;
    }

    .counter-hd-1,
    .counter-hd-2 {
        font-size: 3rem;
        font-weight: 800;
    }

    .counter-hd-1 {
        top: -33px;
    }

    .counter-hd-2 {
        bottom: -39px;
    }

    .counter-card {
        width: 48%;
        height: 48%;
    }
}

@media only screen and (max-width:490px) {
    .counter-main {
        height: 130vh;
    }

    .counter-hd-1,
    .counter-hd-2 {
        font-size: 2.5rem;
        font-weight: 800;
    }

    .counter-hd-1 {
        top: -23px;
    }

    .counter-hd-2 {
        bottom: -35px;
    }

    .counter-card {
        width: 48%;
        height: 48%;
    }
}

@media only screen and (max-width:415px) {
    .counter-hd-1 {
        top: -23px;
    }

    .counter-hd-2 {
        bottom: -75px;
    }
}