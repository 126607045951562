.website-hero-container {
    background-image: url('../../Assets/Websites/service-hero.png');
}

.img-container .website-img-div {
    width: 550px;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 20px;
}

.website-img-div>img {
    width: 100%;
    height: 100%;
}