header {
    width: 100%;
    height: 100px;
    background: transparent;
    position: fixed;
    z-index: 1000;
}

.srcoll-header {
    backdrop-filter: blur(15px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

}

.nav-conatiner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0;
}

.logo-div {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-div>img {
    width: 100%;
}

.nav-conatiner>ul {
    width: 60%;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    height: 100%;
}

.nav-links,
.active-links {
    position: relative;
    color: white;
    text-decoration: none;
    padding: 15px;
}

.nav-links:hover,
.active-links {
    background-color: white;
    border-radius: 25px;
    color: var(--color-2);
}

/* Slider */


.menu-icon {
    width: 60px;
    height: 60px;
    border: 1px solid white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
    display: none;
    margin-right: 10px;
}

.slider-main {
    position: fixed;
    height: 100vh;
    width: 20%;
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    z-index: 1000;
    padding: 20px;
    top: 0;
    right: 0;
}


.slider-main>h2 {
    position: relative;
}


.slider-main>h2>span {
    width: 60px;
    height: 60px;
    position: absolute;
    border: 1px solid white;
    right: 0;
    top: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.slider-main>h2>img {
    width: 85%;
}

.slider-main>ul {
    width: 100%;
    height: 50%;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-weight: 500;
    font-size: 24px;
}

.slider-main>ul>li {
    display: flex;
    justify-content: center;
    width: 100%;
}

.res-nav-links {
    width: 100%;
}

@media only screen and (max-width:997px) {
    .logo-div {
        width: 40%;
    }

    .nav-conatiner>ul {
        display: none;
    }

    .menu-icon {
        display: flex;
    }

    .slider-main {
        width: 50%;
    }


    .slider-main>ul {
        font-size: 18px;
    }
}

@media only screen and (max-width:576px) {
    .slider-main {
        width: 80%;
    }
}